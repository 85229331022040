
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
// pages/[...slug]/index.tsx
import { useEffect } from 'react';

import { useRouter } from 'next/router';

import { NextPageWithLayout } from '~types/NextPageWithLayout';

const RedirectPage: NextPageWithLayout = () => {
  const router = useRouter();
  const { slug } = router.query;

  if (!slug) return null;

  const path = Array.isArray(slug) ? slug[0] : slug;

  if (path === 'svar-registrert') {
    return null;
  }

  useEffect(() => {
    router.replace('/');
  }, []);

  return null;
};

export default RedirectPage;


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/[...slug]/index',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  